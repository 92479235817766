@import "~@/erp/styles/variables/variables.scss";























































































































































































































































































































































































































.order-product {
  margin-top: -15px;
}
.img-wrap {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    display: block;
    border-radius: 2px;
    cursor: pointer;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-pagination {
    height: 36px;
  }
}

@import "~@/erp/styles/variables/variables.scss";








































































































































































































































































































































































































































































































































































































































.el-form {
  padding-right: 5px;
  height: calc(100vh - 190px);
  overflow: auto;
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
.column-sty {
  display: flex;
  .el-input {
    padding-right: 5px;
  }
  i {
    font-size: 24px;
    cursor: pointer;
    margin-top: 5px;
    color: #409eff;
  }
}
::v-deep {
  .el-select {
    width: 100%;
  }
  .el-table__footer-wrapper {
    .has-gutter {
      height: 40px;
      font-family: 'PingFang Bold';
      .required-th::before {
        display: none;
      }
      td {
        text-align: center;
      }
    }
  }
}
